const formatarData = (data) => {
    if (data) return data.split("T")[0].split("-").reverse().join("/"); else return "";
};

const formatarDataHora = (dataInput) => {
    if (dataInput) {
        let dataHora = dataInput.split("T");
        let data = dataHora[0].split("-").reverse().join("/");
        let hora = dataHora[1].split(".")[0];
        return data + " " + hora;
    } else return "";
};

export {formatarData, formatarDataHora};
