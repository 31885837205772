import axiosInstance from "./baseService.service";

const API_URL = "comissao/";

const comissaoService = {

    gerarComissao: async (params) => {
        try {
            const response = await axiosInstance.post(API_URL + "gerarComissao", params);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    gerarRelatorio: async (dto) => {
        try {
            const response = await axiosInstance.post(API_URL + "relatorio", dto, { responseType: "blob" });
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    cadastrarComissao: async (comissao) => {
        try {
            const response = await axiosInstance.post(API_URL + "cadastrarComissao", comissao);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    pesquisarComissao: async (id) => {
        try {
            const response = await axiosInstance.get(API_URL + "pesquisarComissao?id=" + id);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

};

export default comissaoService;
