import axiosInstance from "./baseService.service";

const API_URL = "agendamento/documento";

const agendamentoDocumentoService = {
    cadastrar: async (documento) => {
        try {
            const response = await axiosInstance.post(API_URL, documento);
            return response.data;
        } catch (error) {
            throw error;
        }
    }, alterar: async (documento) => {
        try {
            const response = await axiosInstance.put(API_URL, documento);
            return response.data;
        } catch (error) {
            throw error;
        }
    }, pesquisar: async (idDocumento, idAgendamento, idPaciente) => {
        try {
            const params = new URLSearchParams();

            if (idDocumento) params.append("idDocumento", idDocumento);
            if (idAgendamento) params.append("idAgendamento", idAgendamento);
            if (idPaciente) params.append("idPaciente", idPaciente);

            const response = await axiosInstance.get(`${API_URL}?${params.toString()}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
};

export default agendamentoDocumentoService;
