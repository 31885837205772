import React, {useState} from "react";
import {Button} from "primereact/button";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Dialog} from "primereact/dialog";
import {Dropdown} from "primereact/dropdown";
import {classNames} from "primereact/utils";
import {InputTextarea} from "primereact/inputtextarea";
import {useFormik} from "formik";
import {formatarDataHora} from "../../../util/FormatarData";
import authService from "../../../services/auth.service";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import agendamentoDocumentoService from "../../../services/agendamentoDocumento.service";


const DialogDocumentoComponent = ({dialogVisible, setDialogVisible, agendamento, mostrarMensagem}) => {

    const permissaoCadastroDocumento = authService.checkIfHasPermissao(["CAD_AGENDAMENTO_DOCUMENTO"]);
    const permissaoAlteracaoDocumento = authService.checkIfHasPermissao(["ALT_AGENDAMENTO_DOCUMENTO"]);

    const [documentos, setDocumentos] = useState([]);
    const [exibirCadastro, setExibirCadastro] = useState(true);
    const [loadingSubmit, setLoadingSubmit] = useState(false);


    const pesquisarDocumentos = () => {
        setDocumentos([]);
        agendamentoDocumentoService.pesquisar(null, agendamento.id, null).then((result) => {
            setDocumentos(result);
            setExibirCadastro(!(result.length > 0));
        });
    };

    const irPesquisa = () => {
        pesquisarDocumentos();
        setExibirCadastro(false);
        limparFormik();
        setLoadingSubmit(false);
    }

    const abrirDocumento = (idDocumento) => {
        agendamentoDocumentoService.pesquisar(idDocumento, null, null).then((result) => {
            formik.setValues(result[0]);
            setExibirCadastro(true);
        });
    };

    const formik = useFormik({
        initialValues: {
            id: "", textoObjetivo: "", textoIntervecao: "", textoContinuidade: "", permiteAlterarSeguro: false
        }, validate: (values) => {
            let errors = {};
            if (!values.textoObjetivo) {
                errors.textoObjetivo = "Campo obrigatório";
            }
            if (!values.textoIntervecao) {
                errors.textoIntervecao = "Campo obrigatório";
            }
            if (!values.textoContinuidade) {
                errors.textoContinuidade = "Campo obrigatório";
            }
            return errors;
        }, onSubmit: (values) => {
            setLoadingSubmit(true);
            if (values.id) {
                agendamentoDocumentoService.alterar(values)
                    .then(() => {
                        irPesquisa();
                        mostrarMensagem("Documento alterado com sucesso!", "success", null, true);
                    })
                    .catch((error) => {
                        setLoadingSubmit(false);
                        mostrarMensagem(error, "error", null, true);
                    });
            } else {
                agendamentoDocumentoService
                    .cadastrar({agendamentoId: agendamento.id, ...values})
                    .then(() => {
                        irPesquisa();
                        mostrarMensagem("Documento cadastrado com sucesso!", "success", null, true);
                    })
                    .catch((error) => {
                        setLoadingSubmit(false);
                        mostrarMensagem(error, "error", null, true);
                    });
            }
        },
    });

    const limparFormik = () => {
        formik.resetForm();
        formik.setFieldValue("seguro", false);
    }

    const irParaCadastro = () => {
        limparFormik();
        setExibirCadastro(true);
    }

    const botaoVoltar = () => {
        limparFormik();
        setExibirCadastro(false);
        if (documentos.length === 0) setDialogVisible(false);
    }

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <></>;
    };

    const headerGroup = (<ColumnGroup>
        <Row>
            <Column header="Seguro" field="seguro" sortable rowSpan={3}/>
        </Row>
        <Row>
            <Column header="Cadastro" colSpan={2}/>
            <Column header="Alteração" colSpan={2}/>
            <Column/>
        </Row>
        <Row>
            <Column header="Usuário" field="nomeUsuarioCriacao"/>
            <Column header="Data" sortable field="dataCriacao"/>
            <Column header="Usuário" field="nomeUsuarioAlteracao"/>
            <Column header="Data" sortable field="dataAlteracao"/>
            <Column/>
        </Row>
    </ColumnGroup>);

    return (<Dialog
        onShow={() => {
            limparFormik();
            pesquisarDocumentos();
        }}
        header={<div className="grid">
            <div
                className="field col">
                {!exibirCadastro ? "Documentos" : (formik.values.id ? "Alterar Documento" : "Cadastrar Documento")}
            </div>
            <div className="field col-fixed">
                <Button
                    label="Novo"
                    icon="pi pi-plus"
                    severity="success"
                    className="flex-none"
                    onClick={() => irParaCadastro()}
                    visible={permissaoCadastroDocumento && !exibirCadastro}
                />
            </div>
        </div>}
        visible={dialogVisible}
        onHide={() => setDialogVisible(false)}
        style={{width: "90vw"}}
    >
        {exibirCadastro && <>
            <div className="formgrid grid">
                <div className="field col-12 md:col-4">
                    <label htmlFor="seguro">Seguro?</label>
                    <div className="w-full">
                        <Dropdown
                            id="seguro"
                            name="seguro"
                            inputId="seguroFor"
                            value={formik.values.seguro}
                            onChange={(e) => formik.setFieldValue("seguro", e.value)}
                            options={[{label: "Não", value: false}, {label: "Sim", value: true},]}
                            optionValue="value"
                            placeholder="Selecione"
                            className={classNames({
                                "p-invalid": isFormFieldInvalid("seguro"),
                            }, "w-full")}
                            disabled={formik.values.id && !formik.values.permiteAlterarSeguro}
                        />
                        <small className="p-error">{getFormErrorMessage("seguro")}</small>
                    </div>
                </div>
                <div className="field col-12">
                    <label htmlFor="textoObjetivo">Objetivo</label>
                    <InputTextarea
                        id="textoObjetivo"
                        value={formik.values.textoObjetivo}
                        onChange={(e) => formik.setFieldValue("textoObjetivo", e.target.value)}
                        autoResize={true}
                        style={{height: "100%"}}
                        className={classNames({
                            "p-invalid": isFormFieldInvalid("textoObjetivo"),
                        }, "w-full")}
                        disabled={!permissaoAlteracaoDocumento && formik.values.id}
                    />
                    {getFormErrorMessage("textoObjetivo")}
                </div>
                <div className="field col-12">
                    <label htmlFor="textoIntervecao">Intervenção</label>
                    <InputTextarea
                        id="textoIntervecao"
                        value={formik.values.textoIntervecao}
                        onChange={(e) => formik.setFieldValue("textoIntervecao", e.target.value)}
                        autoResize={true}
                        style={{height: "100%"}}
                        className={classNames({
                            "p-invalid": isFormFieldInvalid("textoIntervecao"),
                        }, "w-full")}
                        disabled={!permissaoAlteracaoDocumento && formik.values.id}
                    />
                    {getFormErrorMessage("textoIntervecao")}
                </div>
                <div className="field col-12">
                    <label htmlFor="textoContinuidade">Continuidade</label>
                    <InputTextarea
                        id="textoContinuidade"
                        value={formik.values.textoContinuidade}
                        onChange={(e) => formik.setFieldValue("textoContinuidade", e.target.value)}
                        autoResize={true}
                        style={{height: "100%"}}
                        className={classNames({
                            "p-invalid": isFormFieldInvalid("textoContinuidade"),
                        }, "w-full")}
                        disabled={!permissaoAlteracaoDocumento && formik.values.id}
                    />
                    {getFormErrorMessage("textoContinuidade")}
                </div>
            </div>
            <div className="buttons">
                <Button label="Salvar" severity="success" icon="pi pi-check" type="submit"
                        onClick={() => formik.handleSubmit()}
                        loading={loadingSubmit}/>
                <Button label="Voltar" onClick={() => botaoVoltar()} className="p-button-secondary"
                        icon="pi pi-times" loading={loadingSubmit}/>
            </div>
        </>}

        {!exibirCadastro && <>
            <DataTable
                value={documentos}
                emptyMessage="Nenhum registro encontrado!"
                paginator
                rows={5}
                dataKey="id"
                selectionMode="single"
                onSelectionChange={(e) => abrirDocumento(e.value.id)}
                headerColumnGroup={headerGroup}
            >

                <Column field={(rowData) => rowData.seguro ? "Sim" : "Não"}
                        style={{width: "1rem", textAlign: "center"}}/>
                <Column field="nomeUsuarioCriacao"></Column>
                <Column field={(rowData) => formatarDataHora(rowData.dataCriacao)}
                        style={{width: "1rem", textAlign: "center"}}/>
                <Column field="nomeUsuarioAlteracao" headerStyle={{textAlign: 'right'}}></Column>
                <Column field={(rowData) => formatarDataHora(rowData.dataAlteracao)}
                        style={{width: "1rem", textAlign: "center"}}/>
                <Column
                    style={{width: "1rem", textAlign: "center"}}
                    body={(rowData) => (<>
                        <Button
                            icon={permissaoAlteracaoDocumento ? "pi pi-pencil" : "pi pi-eye"}
                            size="small"
                            onClick={() => abrirDocumento(rowData.id)}
                        />
                    </>)}
                />
            </DataTable>
        </>}
    </Dialog>);
};

export default DialogDocumentoComponent;
